// Fonts
$Satoshi-Regular: 'Satoshi-Regular';
$Satoshi-Medium:  'Satoshi-Medium';
$Satoshi-Bold:    'Satoshi-Bold';
$Satoshi-Black:   'Satoshi-Black';

//Colors
$primary      : #FF8906;
$secondary    : #CEEECE;
$black        : #312F30;
$white        : #ffffff;
$gray         : #707070;
$gray-100     : #F6F6F6;