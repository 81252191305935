.header{
  background-color: $white;
  box-shadow: 0 3px 36px rgba(0,0,0,.16);
  height: 104px;
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  @media  (max-width: 768px) {
    height: auto;
    padding: 15px 0;
  }

  .header-inner{
    @include display-flex(flex);
    @include align-items(center);
    @media  (max-width: 768px) {
      justify-content: center;
    }
  }
  .bar{
    display: none;
    @media  (max-width: 768px) {
      display: block;
      position: absolute;
      left: 20px;
      top: 15px;
    }
  }
  .logo{
    @media  (max-width: 768px) {
      img{
        width: 120px;
      }
    }
  }
  .menu-right{
    margin-left: auto;
    @include display-flex(flex);
    @include align-items(center);
    justify-content: flex-end;
    @media  (max-width: 768px) {
      justify-content: flex-start;
      flex-wrap: wrap;
      position: absolute;
      left: 0;
      top: 68px;
      width: 100%;
      background-color: $white;
      display: none !important;
      z-index: 999;

      &.active{
        display: flex !important;
        padding-bottom: 40px;
      }
    }
    ul{
      list-style: none;

      li{
        display: inline-block;
        margin: 0 25px;
        a{
          display: block;
          font-family: $Satoshi-Medium;
          color: $black;
          text-decoration: none;
          border-bottom: 2px solid transparent;
          padding: 40px 0;
          transition: border-color 0.3s ease-in-out;
          &:hover,
          &.active{
            border-color: $primary;
          }
        }
      }
      @media  (max-width: 768px) {
        width: 100%;
        li{
          display: block;
          margin: 20px 0;
          a{
            border-bottom: none;
            display: block;
            border-bottom: 0;
            padding: 0 15px;
          }
        }
      }
    }
    .header-btn{
      display: flex;
      align-items: center;
      .btn-create-account{
        margin-left: 40px;
      }
      @media  (max-width: 768px) {
        flex-wrap: wrap;
        width: 100%;
        margin-top: 20px;
        button{
          margin-left: 20px;
        }
      }
    }

  }
}