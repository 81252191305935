*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
    font-family: $Satoshi-Regular;
    @include font-size(16);
    line-height: 1.4;
    color: $gray;
    background-color: $gray-100;

    @media (max-width: 768px) {
      @include font-size(13);
    }
}
