.home{
  margin-top: 104px;
  @media (max-width: 768px) {
    margin-top: 68px;
  }
}

.home-hero{
  @media (max-width: 768px) {
    .container{
      padding: 0;
    }
  }
  .home-hero-inner{
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    flex-wrap: wrap;
    .video{
      position: relative;
      order: 2;
      @include size(50%,calc(100vh - 160px));
      border-radius: 0 0 15px 15px;
      overflow: hidden;
      @media (max-width: 768px) {
        order: 1;
        width: 100%;
        height: 260px;
      }
      img{
        @include size(100%,100%);
        object-fit: cover;
        object-position: top;
      }
      .video-play{
        @include size(70px,70px);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        background-color: transparent;
        @media (max-width: 768px) {
          img{
            @include size(40px,40px);
          }
        }
      }
    }
    .info{
      order: 3;
      position: absolute;
      right: -80px;
      top: 0;
      @media (max-width: 768px) {
        position: static;
        width: 100%;
        order: 2;
        padding-top: 0;
        margin-top: -120px;
      }
      .radial-progress-inner{
        width: 54px !important;
        height: 54px;
        border-radius: 50%;
        overflow: hidden;
        top: 5px;

        img{
          @include size(100%,100%);
          object-fit: cover;
          object-position: center;
        }
      }
      .info-right{
        margin-top: 40px;
      }
      ul{
        list-style: none;
        margin: 0;
        padding: 0;
        @media (max-width: 768px) {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          max-width: 80%;
          margin: 0 auto;
        }
        li{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 15px;
          text-align: center;
          img{
            @include size(48px,48px);
          }
          span{
            display: block;
            color: $primary;
            @include font-size(16px);
            font-family: $Satoshi-Medium;
          }
          a{
            text-decoration: none;
          }
          @media (max-width: 768px) {
            margin: 0 10px;
          }
        }
      }
    }
    .content{
      order: 1;
      width: 50%;
      padding-right: 100px;
      @media (max-width: 768px) {
        order: 3;
        width: 100%;
        padding: 20px 15px;
      }
      h3{
        @include font-size(48);
        font-family: $Satoshi-Black;
        color: $black;
        margin-bottom: 30px;
        @media (max-width: 768px) {
          @include font-size(24);
          margin-bottom: 10px;
        }
      }
      .download-btn{
        display: flex;
        align-items: center;
        margin-top: 30px;
        .app{
          margin-right: 15px;
        }
        button{
          background-color: transparent;
        }
        .app,
        .play{
          width: 150px;
          img{
            width: 100%;
          }
          @media (max-width: 768px) {
            width: 100px;
          }
        }

      }
    }
  }
}
.modal-wrap{
  overflow: hidden;
}
.video-wrap{
  width: 700px;
  background-color: $white;
  @media (max-width: 768px) {
    width: 96%;
  }
  video{
    width: 100%;
    height: 100%;
    margin-bottom: -10px;
  }
}

.content-showcase-wrap{
  margin-top: 100px;

  .content-inner{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 10px;
    padding: 10px 10px 0 10px;
    margin-bottom: -10px;
    &.bg-yellow{
      background-color: $primary;
    }
    &.bg-red{
      background-color: #FCEFDF;
    }
    &.bg-blue{
      background-color: #CEEECE;
    }
    &.bg-black{
      background-color: $black;
    }
    .cover{
      height: 540px;
      @media (max-width: 768px) {
        height: auto;
      }
    }
    .cover,
    .content{
      width: 50%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    .content{
      padding: 30px;

      h3{
        @include font-size(48);
        font-family: $Satoshi-Bold;
        margin-bottom: 20px;

      }
      @media (max-width: 768px) {
        padding: 20px 15px;
        h3{
          @include font-size(20);
        }
      }
      h3.text-white{
        color: $white;
      }
      p.text-white{
        color: $white;
      }
      h3.text-black{
        color: $black;
      }
      p.text-black{
        color: $gray;
      }
    }
    .cover{
      &.order-2{
        order: 2;
        @media (max-width: 768px) {
          order: 1;
        }
      }
      &.order-1{
        @media (max-width: 768px) {
          order: 2;
        }
      }
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        margin-bottom: -6px;
      }
    }
  }
}

//fun-fact
.fun-fact{
  margin: 100px 0;
  .fun-fact-inner{
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 70px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: 768px) {
      padding: 35px 0;
    }
    .item{
      width: 33.33%;
      @include display-center;
      @media (max-width: 768px) {
        width: 100%;
        justify-content: flex-start;
        padding: 15px 0;
      }
      h3{
        @include font-size(50);
        font-family: $Satoshi-Bold;
        color: $primary;
        margin-right: 20px;
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
          @include font-size(42);
          min-width: 110px;
        }
      }
      p{
        max-width: 180px;
      }
    }
  }
}

.testimonial-section{
    .testimonial-header{
      margin-bottom: 50px;
      h3{
        @include font-size(48);
        font-family: $Satoshi-Bold;
        color: $black;
        margin-bottom: 15px;
        @media (max-width: 768px) {
          @include font-size(24);
        }
      }
      p{
        max-width: 60%;
        @media (max-width: 768px) {
          max-width: 100%;
        }
      }
    }
.testimonial-slide{
  position: relative;
  .slick-arrow{
    position: absolute;
    right: 50px;
    top: -120px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $white;
    padding: 0;
    @media  (max-width: 768px) {
      top: -135px;
    }

    &.slick-next{
      right: 50px !important
    }
    &.slick-prev{
      right: 90px !important;
      left: auto;
    }
  }
  .slick-next:before,
  .slick-prev:before {
    background-color: $white;
    color:  #ddd;
  }
  .slick-next:before,
  .slick-prev:before{
    content: '';
    background-image: url("../../../assets/images/arrows/next.png");
    background-repeat: no-repeat;
    background-position: 4px 3px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .slick-prev:before{
      transform: rotate(180deg);
  }

  .slick-slide{
    padding: 0 20px;
    @media  (max-width: 768px) {
      padding: 0 10px;
    }
  }
}
  .item{
    background-color: $white;
    padding: 30px;
    text-align: left;
    border-radius: 15px;
    @media (max-width: 768px) {
      margin: 0 10px;
    }

    
    .avatar{
      width: 90px;
      height: 90px;
      margin-bottom: 10px;
      img{
        width: 100%;
      }
    }

    
    h3{
      font-size: 18px;
      font-family: $Satoshi-Medium;
      color: $black;
      margin: 15px 0 3px 0;
    }
  }

  
}

//question-area
.question-area{
  padding: 100px 0;
  @media (max-width: 768px) {
    padding: 50px 0;
  }
  .title{
    margin-bottom: 30px;
    h3{
      @include font-size(48);
      font-family: $Satoshi-Bold;
      color: $black;
      margin-bottom: 15px;
      @media (max-width: 768px) {
        @include font-size(20);
      }
    }
  }
  .question-ans{
    .accordion {
      border: none;
      border-radius: 0;
    }
    .accordion__button{
      position: relative;
    }
    .accordion__button:before {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%) rotate(-45deg);
      &[aria-disabled="true"]{
        &:before{
          transform: rotate(45deg);
        }
      }
    }
    .accordion__button[aria-expanded='true']::before,
    .accordion__button[aria-selected='true']::before {
      transform: rotate(45deg);
    }
    .accordion__button {
      background-color: #f4f4f4;
      padding: 1.8rem  0;
      font-size: 16px;
      color: $black;
      font-family: $Satoshi-Bold;
      @media (max-width: 768px) {
        padding: 1rem 0;
      }
    }

    .accordion__panel {
      border-top: none;
      padding: 0.5rem 0;
    }
  }
}

//footer
.footer{
  background-color: #000;
  padding: 100px 0 40px 0;
  text-align: center;
  color: $white;
  @media  (max-width: 768px) {
    padding: 50px 0 20px 0;
  }
  .footer-top{
    h3{
      @include font-size(42);
      font-family: $Satoshi-Bold;
      margin-bottom: 15px;
      @media (max-width: 768px) {
        @include font-size(22);
      }
    }
    p{
      max-width: 60%;
      margin: 0 auto;
      @media  (max-width: 768px) {
        max-width: 90%;
      }
    }
    .footer-btn{
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      a{
        margin: 0 15px;
        text-decoration: none;
        color: $white;
        width: 150px;
        img{
          width: 100%;
        }
      }
      @media  (max-width: 768px) {
        a{
          width: 120px;
          img{
            width: 100%;
          }
        }
      }
    }
  }
  .footer-center{
    margin-top: 130px;
    @media  (max-width: 768px) {
      margin-top: 50px;
    }
    p{
      max-width: 400px;
      margin: 15px auto;
      @media  (max-width: 768px) {
        max-width: 90%;
      }
    }
    ul{
      margin-top: 20px;
      li{
        display: inline-block;
        margin: 0 5px;
        a{
          display: block;
          text-decoration: none;
          color: $white;
        }
      }
    }
    .logo-f{
      width: 150px;
    }
  }
  .footer-inner{
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (min-width: 991px) {
      max-width: 70%;
      margin: 40px auto auto auto;
    }
    a{
      display: inline-block;
      text-decoration: none;
      color: $white;
      margin: 0 15px;
    }
  }
}

//form-box
.form-box{
  .head{
    margin-bottom: 40px;
    text-align: center;
    h3{
      @include font-size(42);
      font-family: $Satoshi-Bold;
      color: $black;
      @media (max-width: 768px) {
        @include font-size(22);
      }
    }
  }
}
.form-footer{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 60px;
  @media (max-width: 768px) {
    margin-top: 15px
  }
  button{
    margin-bottom: 30px;
    background-color: transparent;
  }
  a{
    text-decoration: none;
    font-family: $Satoshi-Bold;
    color:$black;
    display: inline-block;
    margin-left: 10px;
  }
}
.create-account{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  .close{
    position: absolute;
    right: 20px;
    top: 20px;
    background-color: transparent;
    z-index: 9;
    img{
      width: 32px;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
  .content,
  .video-bg{
    width: 50%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .content{
    padding: 100px;
    @media (max-width: 768px) {
      padding: 50px 15px;
    }
  }
  .video-bg{
    background-color: #333;
    height: 100vh;
    display: block;
    @media (max-width: 768px) {
      height: 250px;
    }
    video{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.box-wrap{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: 50px 0;
  .center-box{
    background-color: $white;
    padding: 50px;
    width: 600px;
    max-width: 96%;
    height: auto;

    @media (max-width: 768px) {
      padding: 30px;
    }
  }
}
.digit-boxes{
  display: flex;
  align-items: center;
  justify-content: center;
  .digit-box{
    @include size(80px,64px);
    border-radius: 5px;
    border: 1px solid $gray;
    margin: 0 10px 0 0;
    font-size: 32px;
    text-align: center;
    @media (max-width: 768px) {
      @include size(60px, 54px);
      font-size: 22px;
    }
    &.success{
     border-color: #00A759;
    }
    &.error{
      border-color: #E93939;
    }
  }
}
.hint{
  margin-top: 20px;
  text-align: center;
}
.country-dropdown{
  display: none;
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  left: 0;
  top: 100%;
  min-width: 5rem;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0,0,0,0.2);
  z-index: 9999;
  background-color: $white;

  &.active{
    display: block;
  }
  ul{
    li{
      a{
        color: $black;
        display: flex;
        align-items: center;
        padding: 10px;
        text-decoration: none;
        font-family: $Satoshi-Medium;
        img{
          margin-right: 10px;
          width: 24px;
        }
      }
    }
  }
}
.account-submit{
  height: 72px;
  width: 240px;
  background-color: $primary !important;
  color: $white;
  font-size: 20px;
  font-family:$Satoshi-Medium;
  border: none;
  border-radius: 5px;

}

//success-wrap
.success-wrap{
  background-image: url("../../images/bg-success.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;


  .flex-start{
    display: flex;
    align-items: center;
    height: 100%;

  }
  .box{
    background-color: $primary;
    padding: 50px;
    max-width: 50%;
    color: $white;
    @media (max-width: 768px) {
      padding: 30px;
      max-width: 90%;
    }

    h3{
      @include font-size(42);
      font-family: $Satoshi-Bold;
      color: $white;
      @media (max-width: 768px) {
        @include font-size(22);
      }
    }
    .download-btn{
      display: flex;
      align-items: center;
      margin: 30px 0 40px 0;
      .app,
      .play{
        width: 120px;
        img{
          width: 100%;
        }
      }
      button{
        background-color: transparent;
        margin-right: 15px;
      }

    }
    a{
      color: $white;
      font-size: 20px;
      display: block;
      margin-bottom: 40px;
    }
    .logo{
      width: 140px;
      img{
        width: 100%;
      }
    }
  }

}
.circle-wrap {
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 50%;
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
  @media (max-width: 768px) {
    margin-bottom: 40px;
    margin-left: 20px;
  }
}

.circle-wrap .circle .mask,
.circle-wrap .circle .fill {
  width: 60px;
  height: 60px;
  position: absolute;
  border-radius: 50%;
}

.circle-wrap .circle .mask {
  clip: rect(0px, 60px, 60px, 30px);
}

.circle-wrap .inside-circle {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}

/* color animation */

/* 3rd progress bar */
.mask .fill {
  clip: rect(0px, 30px, 60px, 0px);
  background-color:#ff8906;
}

.mask.full,
.circle .fill {
  animation: fill ease-in-out 3s;
  transform: rotate(135deg);
}

@keyframes fill{
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(135deg);
  }
}

//logo-bar
.logo-bar{
  display: none;
  cursor: pointer;
  &.active{
    display: block;
  }
}
.logo-close{
  display: none;
  cursor: pointer;
  &.active{
    display: block;
  }
}

.height-80{
  height: 80px;
}

