
.ReactModal__Overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.ReactModal__Content{
  padding: 0 !important;
  border-radius: 0 !important;
  border: none !important;
}


.btn-close {
  @include size(40px,40px);
  @include absolute(20px,20px);
  border: none;
  background: transparent;
  z-index: 9999999;
  img{
    @include size(100%,100%);
  }
}

